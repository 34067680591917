import pick from 'lodash/pick';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { fetchStripeAccountFromApi, stripeOnBoardingUsingApi } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StripePayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

export const STRIPE_ONBOARDING_REQUEST = 'app/StripePayoutPage/STRIPE_ONBOARDING_REQUEST';
export const STRIPE_ONBOARDING_SUCCESS = 'app/StripePayoutPage/STRIPE_ONBOARDING_SUCCESS';
export const STRIPE_ONBOARDING_ERROR = 'app/StripePayoutPage/STRIPE_ONBOARDING_ERROR';

export const FETCH_STRIPE_ACCOUNT_DETAILS = 'app/StripePayoutPage/FETCH_STRIPE_ACCOUNT_DETAILS';
export const FETCH_STRIPE_ACCOUNT_DETAILS_SUCCESS =
  'app/StripePayoutPage/FETCH_STRIPE_ACCOUNT_DETAILS_SUCCESS';
export const FETCH_STRIPE_ACCOUNT_DETAILS_ERROR =
  'app/StripePayoutPage/FETCH_STRIPE_ACCOUNT_DETAILS_ERROR';
// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
  stripeOnboardingInProgress: false,
  stripeOnboardingError: null,
  status: null,
  fetchStripeAccountDetailsInProgress: false,
  fetchStripeAccountDetailsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };
    case STRIPE_ONBOARDING_REQUEST:
      return { ...state, stripeOnboardingInProgress: true, stripeOnboardingError: null };
    case STRIPE_ONBOARDING_ERROR:
      return { ...state, stripeOnboardingInProgress: false, stripeOnboardingError: payload };
    case STRIPE_ONBOARDING_SUCCESS:
      return { ...state, stripeOnboardingInProgress: false, stripeOnboardingError: null };
    case FETCH_STRIPE_ACCOUNT_DETAILS:
      return {
        ...state,
        fetchStripeAccountDetailsInProgress: true,
        fetchStripeAccountDetailsError: null,
        status: null,
      };
    case FETCH_STRIPE_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        fetchStripeAccountDetailsInProgress: false,
        fetchStripeAccountDetailsError: null,
        status: payload,
      };
    case FETCH_STRIPE_ACCOUNT_DETAILS_ERROR:
      return {
        ...state,

        fetchStripeAccountDetailsInProgress: false,
        fetchStripeAccountDetailsError: payload,
      };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

export const stripeOnboardingRequest = () => ({
  type: STRIPE_ONBOARDING_REQUEST,
});
export const stripeOnboardingError = error => ({
  type: STRIPE_ONBOARDING_ERROR,
  payload: error,
});
export const stripeOnboardingSuccess = () => ({
  type: STRIPE_ONBOARDING_SUCCESS,
});
// ================ Thunks ================ //

export const savePayoutDetails = (values, isUpdateCall) => (dispatch, getState, sdk) => {
  const upsertThunk = isUpdateCall ? updateStripeAccount : createStripeAccount;
  dispatch(savePayoutDetailsRequest());

  return dispatch(upsertThunk(values, { expand: true }))
    .then(response => {
      dispatch(savePayoutDetailsSuccess());
      return response;
    })
    .catch(() => dispatch(savePayoutDetailsError()));
};
export const stripeOnboarding = (action, url) => (dispatch, getState, sdk) => {
  dispatch(stripeOnboardingRequest());
  return stripeOnBoardingUsingApi({ action, url })
    .then(response => {
      const urlLink = response?.accountLinkUrl;
      if (urlLink) {
        window.location.href = urlLink;
      }
      dispatch(stripeOnboardingSuccess());
      return response;
    })
    .catch(e => dispatch(stripeOnboardingError(storableError(e))));
};
export const fetchStripeAccountDetails = () => (dispatch, getState, sdk) => {
  dispatch({ type: FETCH_STRIPE_ACCOUNT_DETAILS });
  return fetchStripeAccountFromApi({})
    .then(response => {
      const status = response?.status;
      dispatch({ type: FETCH_STRIPE_ACCOUNT_DETAILS_SUCCESS, payload: status });
      return response;
    })
    .catch(e => {
      dispatch({ type: FETCH_STRIPE_ACCOUNT_DETAILS_ERROR, payload: storableError(e) });
      throw e;
    });
};
export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  return dispatch(fetchCurrentUser()).then(response => {
    const currentUser = getState().user.currentUser;
    const stripeAccount = currentUser?.attributes?.profile?.metadata?.stripeAccountId;
    if (currentUser && stripeAccount) {
      dispatch(fetchStripeAccountDetails());
    }
    return response;
  });
};
