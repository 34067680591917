import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { languageType } from '../../util/languageType';
import { getUserByUserName } from '../../util/api';
import axios from 'axios';
// import { user } from '../../ducks';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

export const USER_NOT_FOUND = 'app/ProfilePage/USER_NOT_FOUND';

export const SET_LOADING_TRUE = 'app/ProfilePage/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'app/ProfilePage/SET_LOADING_FALSE';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
  userNotfound: false,
  loading: false,
  pagination: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],
        pagination: null,
        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs, pagination: payload.pagination };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload, pagination: null };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };
    case USER_NOT_FOUND:
      return { ...state, userNotfound: true };
    case SET_LOADING_TRUE:
      return { ...state, loading: true };
    case SET_LOADING_FALSE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = payload => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs: payload.listingRefs, pagination: payload.pagination },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});
export const userNotFound = () => ({
  type: USER_NOT_FOUND,
});
export const loadingTrue = () => ({
  type: SET_LOADING_TRUE,
});
export const loadingFalse = () => ({
  type: SET_LOADING_FALSE,
});

// loadingTrue
// SET_LOADING
// ================ Thunks ================ //

export const queryUserListings = (userId, config, search) => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  const { page = 1 } = queryParams;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return sdk.listings
    .query({
      author_id: userId,
      minStock: 1,
      page,
      perPage: 24,
      include: ['author', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
        'variants.default',
      ],
      'limit.images': 1,
      // ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      // ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    })
    .then(response => {
      // Pick only the id and type properties from the response listings
      console.log('response', response);
      const listingRefs = response.data.data.map(item => {
        const { id, type, attributes } = item || {};
        //  const { attributes } = data?.data?.data ||{};
        const { title, description, publicData } = attributes || {};
        if (languageType() === 'en-US') {
          attributes.title = publicData?.en_title ? publicData?.en_title : attributes?.title;
          attributes.description = publicData.en_description
            ? publicData.en_description
            : attributes.description;
        } else {
          attributes.title = publicData?.el_title ? publicData?.el_title : attributes?.title;

          attributes.description = publicData?.el_description
            ? publicData?.el_description
            : attributes?.description;
        }

        return { id, type };
      });
      const payload = {
        listingRefs,
        pagination: response.data.meta,
      };
      dispatch(addMarketplaceEntities(response));
      dispatch(queryListingsSuccess(payload));
      return response;
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdk.reviews
    .query({
      subject_id: userId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  console.log('userId----', userId);

  return sdk.users
    .show({
      id: userId,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(showUserSuccess());
      dispatch(loadingFalse());
      return response;
    })
    .catch(e => dispatch(showUserError(storableError(e))));
};

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  const userName = params?.id;
  dispatch(loadingTrue());
  if (userName == 'userNameNotPresent') {
    dispatch(userNotFound());

    dispatch(loadingFalse());
    return;
  }
 const url= process.env.REACT_APP_MARKETPLACE_ROOT_URL==='http://localhost:3000'?'http://localhost:3500' : process.env.REACT_APP_MARKETPLACE_ROOT_URL
    try {
      const res =await axios.post(`${url}/api/get-user`, {userName}, {
        headers: { 'Content-Type': 'application/transit+json' }
      });

      //  await getUserByUserName({ userName });

      if (res?.data?.data?.meta?.totalItems < 1) {
        dispatch(loadingFalse());
        dispatch(userNotFound());
      }
      const { uuid } = res?.data?.data?.data[0]?.id || {};
      const userId = new UUID(uuid);
      // Clear state so that previously loaded data is not visible
      // in case this page load fails.
      dispatch(setInitialState());

      return Promise.all([
        dispatch(fetchCurrentUser()),
        dispatch(showUser(userId)),
        dispatch(queryUserListings(userId, config, search)),
        dispatch(queryUserReviews(userId)),
        
      ]);
    } catch (e) {
      dispatch(loadingFalse());
      dispatch(showUserError(storableError(e)));
    }
  // }
};
